<template>
    <div>
        <div v-bind:class="position=='top' ? 'divider-top' : 'divider-bottom'" v-bind:style="{'background': background || '#F5F5F5'}">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <defs><linearGradient id="gradient"><stop offset="0%" stop-color="#d5420f"></stop><stop offset="100%" stop-color="#fe8a00"></stop></linearGradient></defs>
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" v-bind:fill="foreground=='gradient' ? 'url(#gradient)' : (foreground || '#FFFFFF')"></path>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'WaveDivider',
        props: {
            position: String,
            foreground: String,
            background: String,
        }
    }
</script>

<style scoped>
    .divider-top {
        width: 100%;
        overflow: hidden;
        line-height: 0;
    }

    .divider-top svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 61px;
    }

    .divider-bottom {
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    .divider-bottom svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 53px;
    }
</style>