<template>
    <div class="route-page">
        <NavBar selecteditem="home" />
        <Onboarding />
        <WaveDivider background="rgba(0,0,0,0.5)"></WaveDivider>
        <div class="subsection mob-center scroll-reveal">
            <h2><i class="fas fa-school text-gradient" style="padding-right: 16px;"></i>About Yasham</h2>
            <span class="underbar"></span>
            <p style="line-height: 28px">
                <b>Educate. Enlighten. Empower.</b><br>
                – a mind that challenges itself is a mind that shines through tough circumstances.<br>
                Our mission is to remind everyone of their humanity, to be better and do better every single day.<br>
                We serve to enlighten today’s minds, so they may be empowered to do the same for the generations to come.
                <br><br>
                Yasham works each day to empower society holistically, through changes big or small. To be able to guide and assist the many promising minds is one of the most gratifying feelings that motivate us. We predominantly work in the domain of education, to sharpen young minds today so that they might red-pencil the labels which have been harshly assigned to them. Our endeavour is to provide the assistance children require, using the latest tools and technology, irrespective of their background. To achieve our vision, we play an active role as a mediator between a child's aspirations and the acquisition of their goals.
            </p>
        </div>

        <Statistics />

        <div class="subsection mob-center scroll-reveal">
            <h2><i class="fas fa-school text-gradient" style="padding-right: 16px;"></i>Recent Programmes</h2>
            <span class="underbar"></span>
            <div class="cards-holder">
                <CardItem class="mcard" v-for="(item, index) in programmes" :route="'programmes/'+item.id" :key="index" :item="item"></CardItem>
            </div>
            <router-link class="bigbtn" style="margin-top: 32px;" to="what-we-do">View More</router-link>
        </div>

        <WaveDivider position="bottom" foreground="#F5F5F5" background="#FFFFFF" />

        <div class="subsection mob-center scroll-reveal" style="background: #F5F5F5">
            <h2><i class="fas fa-school text-gradient" style="padding-right: 16px;"></i>Testimonials</h2>
            <span class="underbar"></span>
            <iframe class="yt-embed" src="https://www.youtube.com/embed/NpEaa2P7qZI">
            </iframe>
        </div>

        <Footer />
    </div>
</template>

<script>
    /* eslint-disable no-unused-vars */
    
    import NavBar from '@/components/NavBar.vue'
    import Footer from '@/components/Footer.vue'
    import WaveDivider from '@/components/WaveDivider.vue'
    import CardItem from '@/components/CardItem.vue'
    import Onboarding from './Landing.vue'
    import Statistics from './Statistics.vue'
    import programmeList from '@/content/programmes.json'

    export default {
        components: {
            NavBar,
            Footer,
            Onboarding,
            Statistics,
            WaveDivider,
            CardItem
        },
        data: function(){
            return {
                programmes: programmeList.slice(0, 2)
            }
        }
    }
</script>

<style>
    .bigbtn{
        display: inline-block;
        border-radius: 64px;
        background: var(--color-gradient);
        color: #FFF;
        padding: 20px 42px;
        width: fit-content;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.25s;
    }

    .bigbtn:hover{
        transform: scale(1.05);
    }

    .bigbtn:active{
        transform: scale(0.95);
    }
</style>

<style scoped>
    .cards-holder{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .mcard{
        margin: 16px 16px 0px 0px;
        flex-grow: 1;
        max-width: calc(50% - 16px);
    }

    @media screen and (max-width: 840px) {
        .cards-holder{
            flex-direction: column;
            align-items: center;
        }
        .mcard{
            margin: 16px 0px 0px 0px;
            max-width: 540px;
            width: 100%;
        }
    }
</style>